.ht-swiper-button-nav {
    background: #201e1e8a;
    font-size: 5rem;
    border: 0;
}

/* Mobile first */
.prev-swiper-nav, 
.next-swiper-nav {
    margin-top: 180px;
}

@media screen and (min-width: 425px) {
    .next-swiper-nav {
        margin-top: 0;
        right: -35px;
    }
    .prev-swiper-nav {
        margin-top: 0;
        left: -35px;
    }
}

@media screen and (min-width: 360px) and (max-width: 425px) {
    .next-swiper-nav {
        margin-top: 130px;
        right: 0;
    }
    .prev-swiper-nav {
        margin-top: 130px;
        left: 0;
    }
}